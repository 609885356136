import {StyleExtractor} from "@kubric/utils";
import React from "react";

import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const WaitlistGlobe = () => {
  return (
    <div className={styler.get("globeWraper")}>
      <img
        className={styler.get("globeImage")}
        src='https://media.kubric.io/api/assetlib/d9729aed-1f56-4850-aed2-e1db0eef1562.gif'
        alt='globe'
      />
      <img
        className={styler.get("templateImage")}
        src='https://media.kubric.io/api/assetlib/a2b0f7be-d183-492d-aecb-89f54ab98942.png'
        alt='template'
      />
    </div>
  );
};

export default WaitlistGlobe;
