import {StyleExtractor} from "@kubric/utils";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";
import {ArrowRight} from "react-feather";

import {useMediaQuery} from "../../../../hooks/useMediaQuery";
import SanityRichText from "../../../commons/SanityRichText";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const WaitlistFeatures = ({data}) => {
  const isMobile = useMediaQuery("(max-width: 800px)");

  return data?.map((element) => {
    const {
      backgroundTexture,
      layoutType,
      image,
      _rawHeading,
      _rawSubHeading,
      cta,
    } = element;
    return (
      <div className={styler.get("waitlistFeaturesWrapper", "full-bleed")}>
        {backgroundTexture && !isMobile && (
          <div className={styler.get("textureBg")} />
        )}
        {!isMobile ? (
          <div
            className={styler.get([
              "contentBox",
              layoutType === "imageText" || backgroundTexture
                ? "rowReverse"
                : "row",
            ])}
          >
            <div className={styler.get("textBox")}>
              <SanityRichText
                renderContainerOnSingleChild
                blocks={_rawHeading}
                className={styler.get("heading")}
              />
              <SanityRichText
                renderContainerOnSingleChild
                blocks={_rawSubHeading}
                className={styler.get("subHeading")}
              />
              {cta && (
                <div className={styler.get("cta")}>
                  <OutboundLink
                    href={cta?.url}
                    id={cta?.idAttribute}
                    target='_blank'
                    referrerPolicy='no-referrer'
                    rel='noopener noreferrer'
                  >
                    {cta?.text}
                  </OutboundLink>
                  <ArrowRight className={styler.get("arrowIcon")} />
                </div>
              )}
            </div>
            <GatsbyImage
              className={styler.get("image")}
              image={image?.image?.asset?.gatsbyImageData}
              alt={image?.alt || "features image"}
              objectFit='cover'
            />
          </div>
        ) : (
          <div className={styler.get("contentBox")}>
            <GatsbyImage
              className={styler.get("image")}
              image={image?.image?.asset?.gatsbyImageData}
              alt={image?.alt || "features image"}
              objectFit='cover'
            />
            <div className={styler.get("textBox")}>
              <SanityRichText
                renderContainerOnSingleChild
                blocks={_rawHeading}
                className={styler.get("heading")}
              />
              <SanityRichText
                renderContainerOnSingleChild
                blocks={_rawSubHeading}
                className={styler.get("subHeading")}
              />
              {cta && (
                <div className={styler.get("cta")}>
                  <OutboundLink
                    href={cta?.url}
                    id={cta?.idAttribute}
                    target='_blank'
                    referrerPolicy='no-referrer'
                    rel='noopener noreferrer'
                  >
                    {cta?.text}
                  </OutboundLink>
                  <ArrowRight className={styler.get("arrowIcon")} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  });
};

export default WaitlistFeatures;
