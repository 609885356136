import {StyleExtractor} from "@kubric/utils";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";
import {ArrowRight} from "react-feather";
import {Link as SpyLink} from "react-scroll";

import {useMediaQuery} from "../../../../hooks/useMediaQuery";
import SanityRichText from "../../../commons/SanityRichText";
import WaitlistGlobe from "../WaitlistGlobe";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const WaitlistScroll = ({data}) => {
  const isMobile = useMediaQuery("(max-width: 800px)");

  const [imageIndex, setImageIndex] = React.useState(0);

  if (isMobile) {
    return data?.map((element) => {
      const {image, _rawHeading, _rawSubHeading, cta} = element;
      return (
        <div className={styler.get("waitlistFeaturesWrapper", "full-bleed")}>
          <div className={styler.get("contentBox")}>
            <GatsbyImage
              className={styler.get("image")}
              image={image?.image?.asset?.gatsbyImageData}
              alt={image?.alt || "features image"}
              objectFit='cover'
            />
            <div className={styler.get("textBox")}>
              <SanityRichText
                renderContainerOnSingleChild
                blocks={_rawHeading}
                className={styler.get("heading")}
              />
              <SanityRichText
                renderContainerOnSingleChild
                blocks={_rawSubHeading}
                className={styler.get("subHeading")}
              />
              {cta && (
                <div className={styler.get("cta")}>
                  <OutboundLink
                    href={cta?.url}
                    id={cta?.idAttribute}
                    target='_blank'
                    referrerPolicy='no-referrer'
                    rel='noopener noreferrer'
                  >
                    {cta?.text}
                  </OutboundLink>
                  <ArrowRight className={styler.get("arrowIcon")} />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <div className={styler.get("waitlistFeaturesWrapper", "full-bleed")}>
      <div className={styler.get(["featuresDesktopContainer"])}>
        <div className={styler.get(["featuresImageContainer"])}>
          {data?.map(({image}, index) => {
            return (
              <SpyLink
                to={index ?? 0}
                className={styler.get([
                  imageIndex === 0 && index === 0
                    ? "defaultImage"
                    : "featureImage",
                ])}
                activeClass={styler.get([
                  "selectedFeatureImage",
                  index !== 0 && imageIndex !== 0 && "animatedFadeIn",
                ])}
                spy
                smooth
                onSetActive={() => {
                  setImageIndex(index);
                }}
                offset={-400}
              >
                {index === 1 ? (
                  <WaitlistGlobe />
                ) : (
                  <GatsbyImage
                    image={image?.image?.asset?.gatsbyImageData}
                    alt={image?.alt || "features image"}
                    objectFit='cover'
                  />
                )}
              </SpyLink>
            );
          })}
        </div>
        <div className={styler.get(["contentTextContainer"])}>
          {data.map((selectedData, index) => {
            const {_rawHeading, _rawSubHeading} = selectedData;
            return (
              <div
                className={styler.get(["textBox", "textContainerDesktop"])}
                id={index}
              >
                <SanityRichText
                  renderContainerOnSingleChild
                  blocks={_rawHeading}
                  className={styler.get("heading")}
                />
                <SanityRichText
                  renderContainerOnSingleChild
                  blocks={_rawSubHeading}
                  className={styler.get("subHeading")}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WaitlistScroll;
