import React from "react";

export const Checkmark = ({
  className,
  onClick,
  size = 47,
  color = "#E8A137",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 47 47'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      color={color}
      onClick={onClick}
    >
      <path
        d='m17.625 21.5416 5.875 5.875L43.0833 7.83331'
        stroke='#E8A137'
        strokeWidth='3'
        strokeLinecap='square'
        strokeLinejoin='round'
      />
      <path
        d='M41.125 23.5v13.7083c0 1.0388-.4126 2.035-1.1472 2.7695-.7345.7346-1.7307 1.1472-2.7695 1.1472H9.79167c-1.03877 0-2.03499-.4126-2.76951-1.1472-.73451-.7345-1.14716-1.7307-1.14716-2.7695V9.79167c0-1.03877.41265-2.03499 1.14716-2.76951C7.75668 6.28765 8.7529 5.875 9.79167 5.875H31.3333'
        stroke='#E8A137'
        strokeWidth='3'
        strokeLinecap='square'
        strokeLinejoin='round'
      />
    </svg>
  );
};
