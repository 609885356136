import {StyleExtractor, isValidString} from "@kubric/utils";
import React from "react";
import Loader from "react-feather/dist/icons/loader";

import {waitlistSubmission} from "../../../../utils/waitlist";
import ErrorField from "../../../ErrorField";
import SanityRichText from "../../../commons/SanityRichText";
import {Checkmark} from "../../../icons/Checkmark";
import {Cross} from "../../../icons/Cross";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const FormStates = {
  IDLE: "idle",
  LOADING: "loading",
  SUCCESS: "success",
};

const WaitListModal = ({data, show, formCloseHandler}) => {
  const {
    waitlistTitle,
    waitlistFullName,
    ecommercePlatform,
    waitlistworkEmail,
    waitlistURL,
    ecommercePlaceholder,
    waitlistFullNamePlaceHolder,
    waitlistURLPlaceholder,
    waitlistworkEmailPlaceholder,
    waitlistFormCTA,
    waitlistEcommercePlatformError,
    waitlistFullNameError,
    waitlistURLError,
    waitlistworkError,
    waitlistFormSuccess,
    _rawWaitlistFormMessage,
    // waitlistFormError,
  } = data;

  const [waitlistDetails, setWaitlistDetails] = React.useState({
    name: "",
    email: "",
    storeUrl: "",
    ecommercePlatform: "",
  });

  const [error, setError] = React.useState(false);
  const [formState, setFormState] = React.useState(FormStates.IDLE);

  if (!show) {
    return null;
  }

  const isFormSubmitted = formState === FormStates.SUCCESS;
  return (
    <div className={styler.get(["waitlistFormWrapper"])}>
      <div className={styler.get(["waitlistForm"])}>
        <Cross
          className={styler.get(["formCloseBtn"])}
          onClick={formCloseHandler}
        />
        <div className={styler.get(["waitlistModalHeadingContainer"])}>
          <p className={styler.get(["waitlistFormTitle"])}>{waitlistTitle}</p>
        </div>
        {isFormSubmitted ? (
          <div className={styler.get(["successFormState"])}>
            <Checkmark />
            <p>{waitlistFormSuccess}</p>
          </div>
        ) : (
          <>
            <div className={styler.get(["waitlistModalMessageContainer"])}>
              <SanityRichText
                renderContainerOnSingleChild
                blocks={_rawWaitlistFormMessage}
              />
            </div>
            <div className={styler.get(["waitlistFields"])}>
              <div className={styler.get(["waitlistFormField"])}>
                <label>{waitlistFullName}</label>
                <input
                  type='text'
                  onChange={(e) =>
                    setWaitlistDetails({
                      ...waitlistDetails,
                      name: e.target.value,
                    })
                  }
                  placeholder={waitlistFullNamePlaceHolder}
                />
                {error && !isValidString(waitlistDetails.name) && (
                  <ErrorField errorMessage={waitlistFullNameError} />
                )}
              </div>
              <div className={styler.get(["waitlistFormField"])}>
                <label>{waitlistworkEmail}</label>
                <input
                  type='email'
                  onChange={(e) =>
                    setWaitlistDetails({
                      ...waitlistDetails,
                      email: e.target.value,
                    })
                  }
                  placeholder={waitlistworkEmailPlaceholder}
                />
                {error && !isValidString(waitlistDetails.email) && (
                  <ErrorField errorMessage={waitlistworkError} />
                )}
              </div>
              <div className={styler.get(["waitlistFormField"])}>
                <label>{waitlistURL}</label>
                <input
                  type='text'
                  onChange={(e) =>
                    setWaitlistDetails({
                      ...waitlistDetails,
                      storeUrl: e.target.value,
                    })
                  }
                  placeholder={waitlistURLPlaceholder}
                />
                {error && !isValidString(waitlistDetails.storeUrl) && (
                  <ErrorField errorMessage={waitlistURLError} />
                )}
              </div>
              <div className={styler.get(["waitlistFormField"])}>
                <label>{ecommercePlatform}</label>
                <input
                  type='text'
                  onChange={(e) =>
                    setWaitlistDetails({
                      ...waitlistDetails,
                      ecommercePlatform: e.target.value,
                    })
                  }
                  placeholder={ecommercePlaceholder}
                />
                {error && !isValidString(waitlistDetails.ecommercePlatform) && (
                  <ErrorField errorMessage={waitlistEcommercePlatformError} />
                )}
              </div>
            </div>
          </>
        )}
        {!isFormSubmitted && (
          <div className={styler.get(["waitlistFormCTAContainer"])}>
            <button
              onClick={() => {
                if (
                  Object.values(waitlistDetails).some(
                    (value) => value.length === 0
                  )
                ) {
                  setError(true);
                } else if (formState === FormStates.IDLE) {
                  setFormState(FormStates.LOADING);
                  waitlistSubmission({...waitlistDetails})
                    .then(() => setFormState(FormStates.SUCCESS))
                    .catch(() => setFormState(FormStates.IDLE));
                }
              }}
              type='button'
              className={styler.get(["waitlistHeroCTA"])}
            >
              {formState === FormStates.IDLE && waitlistFormCTA}
              {formState === FormStates.LOADING && (
                <Loader width={24} className={styler.get("loader")} />
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default WaitListModal;
