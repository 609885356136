import {StyleExtractor} from "@kubric/utils";
import {graphql} from "gatsby";
import React from "react";

import LastSection from "../../components/AboveFooterSection";
import Buffer from "../../components/Buffer";
import Layout from "../../components/Layout";
import StructuredData from "../../components/StructuredData";
import WaitlistApps from "../../components/pages/copilot/WaitlistApps";
import WaitlistFeatures from "../../components/pages/copilot/WaitlistFeatures";
import WaitlistHero from "../../components/pages/copilot/WaitlistHero";
import WaitlistJoin from "../../components/pages/copilot/WaitlistJoin";
import WaitListModal from "../../components/pages/copilot/WaitlistModal";
import WaitlistScroll from "../../components/pages/copilot/WaitlistScroll";
import MMStickyCTA from "../../components/pages/modemagic/StickyCTA";
import {useMediaQuery} from "../../hooks/useMediaQuery";
import "../styles.scss";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const AuditPage = ({data}) => {
  const isMobile = useMediaQuery("(max-width: 800px)");
  const seoData = data.allSanitySeoMeta.nodes[0];
  const {
    waitlistHeroSection,
    waitlistAppsSection,
    waitlistFeatureSection,
    waitlistJoinSection,
    waitlistFormSection,
    waitlistScrollSection,
    waitlistTexturedSection,
  } = data.allSanityWaitlistPage.nodes[0] ?? {};
  const {lastSection} = data.allSanityModemagic3Page.nodes[0] ?? {};

  const [showWaitlistModal, setShowWaitlistModal] = React.useState(false);

  return (
    <Layout
      type='modemagic'
      className={styler.get("auditPageWrap")}
      mmv3
      seoData={seoData}
      contentBuffer={false}
      hideHeaderBackground
      darkMode
      headerProps={{showMMCta: true}}
      noCTAHeader
      masonLogo
      plainFooterBackground
    >
      {/* Waitlist Modal */}
      <WaitListModal
        data={waitlistFormSection}
        show={showWaitlistModal}
        formCloseHandler={() => setShowWaitlistModal(false)}
      />
      <StructuredData />
      <Buffer buffer={0} mobileBuffer={0} />

      {/* Hero Section */}
      <div className='full-bleed'>
        <WaitlistHero
          data={waitlistHeroSection}
          isMobile={isMobile}
          formData={waitlistFormSection}
          formHandler={() => setShowWaitlistModal(true)}
        />
      </div>
      {/* Apps Section */}
      <div className='full-bleed'>
        <WaitlistApps data={waitlistAppsSection} />
      </div>
      {/* Features Section */}
      <WaitlistFeatures data={waitlistFeatureSection} />
      {/* Features Scroll Section */}
      <WaitlistScroll data={waitlistScrollSection} />
      {/* Features Textured Section */}
      <WaitlistFeatures data={waitlistTexturedSection} />
      {/* Join Section */}
      <div className='full-bleed'>
        <WaitlistJoin
          data={waitlistJoinSection}
          isMobile={isMobile}
          formHandler={() => setShowWaitlistModal(true)}
        />
      </div>
      <section className={styler.get("footerWrap", "full-bleed")}>
        <LastSection
          data={lastSection}
          hideTicketSectionInDesktop
          hideTicketSection
          plainBackground
        />
      </section>
      {isMobile && (
        <MMStickyCTA
          data={waitlistHeroSection.waitlistHeroCTA}
          onClick={() => setShowWaitlistModal(true)}
          darkMode
        />
      )}
    </Layout>
  );
};

export const query = graphql`
  query {
    allSanitySeoMeta(filter: {page: {eq: "copilot"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
    allSanityWaitlistPage {
      nodes {
        waitlistFormSection {
          ecommercePlaceholder
          ecommercePlatform
          waitlistFormCTA
          waitlistEcommercePlatformError
          waitlistFullName
          waitlistFullNameError
          waitlistFullNamePlaceHolder
          waitlistTitle
          waitlistURL
          waitlistURLError
          waitlistURLPlaceholder
          waitlistworkEmail
          waitlistworkEmailPlaceholder
          waitlistworkError
          waitlistFormSuccess
          waitlistFormError
          _rawWaitlistFormMessage
        }
        waitlistHeroSection {
          _rawWaitlistHeroHeading
          _rawWaitlistHeroSubHeading
          waitlistHeroCTA {
            text
          }
        }
        waitlistAppsSection {
          _rawWaitlistAppHeading
          _rawWaitlistAppSubHeading
          waitlistAppsSection {
            waitlistAppHeading
            waitlistAppImage {
              image {
                asset {
                  gatsbyImageData
                }
              }
            }
          }
        }
        waitlistFeatureSection {
          backgroundTexture
          layoutType
          _rawHeading
          _rawSubHeading
          image {
            image {
              asset {
                gatsbyImageData
              }
            }
          }
          cta {
            text
            url
            idAttribute
          }
        }
        waitlistScrollSection {
          backgroundTexture
          layoutType
          _rawHeading
          _rawSubHeading
          image {
            image {
              asset {
                gatsbyImageData
              }
            }
          }
          cta {
            text
            url
            idAttribute
          }
        }
        waitlistTexturedSection {
          backgroundTexture
          layoutType
          _rawHeading
          _rawSubHeading
          image {
            image {
              asset {
                gatsbyImageData
              }
            }
          }
          cta {
            text
            url
            idAttribute
          }
        }
        waitlistJoinSection {
          _rawWaitlistAppHeading
          _rawWaitlistJoinSubheading
          waitlistJoinCTA {
            text
            responseText
            url
            idAttribute
            mobileRedirectUrl
          }
        }
      }
    }
    allSanityModemagic3Page: allSanityModemagicPage(
      filter: {page: {eq: "mmv3"}}
    ) {
      nodes {
        midSection {
          _rawText
        }
        lastSection {
          _rawHeading
          _rawFinalHeading
          _rawDescription
          cta
          placeholder
        }
      }
    }
  }
`;

export default AuditPage;
