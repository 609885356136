// extracted by mini-css-extract-plugin
export var animatedFadeIn = "styles-module--animatedFadeIn--14y_x";
export var arrowIcon = "styles-module--arrowIcon--3oGDM";
export var contentBox = "styles-module--contentBox--1fs68";
export var contentTextContainer = "styles-module--contentTextContainer--cc0EZ";
export var cta = "styles-module--cta--1K0WM";
export var defaultImage = "styles-module--defaultImage--Xd5gO";
export var fadeIn = "styles-module--fadeIn--9o5Sl";
export var fadeOut = "styles-module--fadeOut--2NaYN";
export var featureImage = "styles-module--featureImage--2pPGE";
export var featuresDesktopContainer = "styles-module--featuresDesktopContainer--3ndoh";
export var featuresImageContainer = "styles-module--featuresImageContainer---pmS6";
export var heading = "styles-module--heading--3-VOq";
export var image = "styles-module--image--ve2ef";
export var selectedFeatureImage = "styles-module--selectedFeatureImage--17FP3";
export var subHeading = "styles-module--subHeading--2ujM-";
export var textBox = "styles-module--textBox--2jW2N";
export var textContainerDesktop = "styles-module--textContainerDesktop--1JFoR";
export var textureBg = "styles-module--textureBg--10eOc";
export var waitlistFeaturesWrapper = "styles-module--waitlistFeaturesWrapper--2zT6J";