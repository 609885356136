import React from "react";

export const AlertCircle = ({height = 18, width = 18, className, color}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      color={color}
      viewBox='0 0 18 18'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_4763_8461)'>
        <path
          d='M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z'
          fill='#D64242'
          stroke='#D64242'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9 6V9'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9 12H9.0075'
          stroke='white'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_4763_8461'>
          <rect width='18' height='18' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
