import {StyleExtractor} from "@kubric/utils";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";
import Slider from "react-slick";

import SanityRichText from "../../../commons/SanityRichText";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

export default function WaitlistApps({data}) {
  const {
    _rawWaitlistAppHeading,
    _rawWaitlistAppSubHeading,
    waitlistAppsSection,
  } = data;

  const sliderSettings = {
    dots: false,
    infinite: true,
    touchMove: true,
    className: "powerSlider",
    slidesToScroll: 1,
    variableWidth: true,
    slidesToShow: 3,
    arrows: false,
    autoplay: true,
    speed: 12000,
    autoplaySpeed: 1,
    cssEase: "linear",
  };
  return (
    <div className={styler.get(["waitlistAppsWrapper"])}>
      <div className={styler.get(["headingBox"])}>
        {/* Heading */}
        <div className={styler.get(["heading"])}>
          <SanityRichText
            renderContainerOnSingleChild
            blocks={_rawWaitlistAppHeading}
          />
        </div>

        {/* Subheading */}
        <div className={styler.get(["subHeading"])}>
          <SanityRichText
            renderContainerOnSingleChild
            blocks={_rawWaitlistAppSubHeading}
          />
        </div>
      </div>
      <Slider {...sliderSettings}>
        {waitlistAppsSection.map(
          ({waitlistAppHeading, waitlistAppImage: image}) => {
            return (
              <div className={styler.get(["waitlistAppCard"])}>
                <div className={styler.get(["cardContent"])}>
                  <p>{waitlistAppHeading}</p>
                  <GatsbyImage
                    image={image?.image?.asset?.gatsbyImageData}
                    className={styler.get(["sliderAppImage"])}
                  />
                </div>
              </div>
            );
          }
        )}
      </Slider>
    </div>
  );
}
