// extracted by mini-css-extract-plugin
export var arrowIcon = "styles-module--arrowIcon--Ags6k";
export var contentBox = "styles-module--contentBox--22xG0";
export var cta = "styles-module--cta--1ro5y";
export var heading = "styles-module--heading--2zPn_";
export var image = "styles-module--image--1cd_t";
export var row = "styles-module--row--MGxQk";
export var rowReverse = "styles-module--rowReverse--3ojpe";
export var subHeading = "styles-module--subHeading--vXphm";
export var textBox = "styles-module--textBox--3I_dh";
export var textureBg = "styles-module--textureBg--2c1we";
export var waitlistFeaturesWrapper = "styles-module--waitlistFeaturesWrapper--19YuC";