// extracted by mini-css-extract-plugin
export var formCloseBtn = "styles-module--formCloseBtn--1Bo53";
export var loader = "styles-module--loader--18Fd5";
export var spin = "styles-module--spin--3BgK1";
export var successFormState = "styles-module--successFormState--3pRJH";
export var waitlistFields = "styles-module--waitlistFields--3S5Sm";
export var waitlistForm = "styles-module--waitlistForm--1me57";
export var waitlistFormCTAContainer = "styles-module--waitlistFormCTAContainer--2id4k";
export var waitlistFormField = "styles-module--waitlistFormField--3s8PU";
export var waitlistFormTitle = "styles-module--waitlistFormTitle--aIFYr";
export var waitlistFormWrapper = "styles-module--waitlistFormWrapper--3bT3l";
export var waitlistHeroCTA = "styles-module--waitlistHeroCTA--1_JRl";
export var waitlistModalHeadingContainer = "styles-module--waitlistModalHeadingContainer--2T503";
export var waitlistModalMessageContainer = "styles-module--waitlistModalMessageContainer--Au1ZY";