import React from "react";

export const Cross = ({className, onClick, size = 24, color = "#808693"}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      color={color}
      onClick={onClick}
    >
      <g filter='url(#filter0_d_7232_15574)'>
        <path
          d='M18 4L6 16'
          stroke='currentColor'
          strokeWidth='2.17213'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6 4L18 16'
          stroke='currentColor'
          strokeWidth='2.17213'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_7232_15574'
          x='-1.34426'
          y='-1.17213'
          width='26.6885'
          height='26.6885'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2.17213' />
          <feGaussianBlur stdDeviation='2.17213' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_7232_15574'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_7232_15574'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};
