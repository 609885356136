import {StyleExtractor} from "@kubric/utils";
import React from "react";

import {AlertCircle} from "../icons/AlertCircle";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const ErrorField = ({errorMessage}) => {
  return (
    <div className={styler.get("errorContainer")}>
      <AlertCircle /> <span>{errorMessage}</span>
    </div>
  );
};

export default ErrorField;
