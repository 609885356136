import {StyleExtractor} from "@kubric/utils";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import React from "react";

import SanityRichText from "../../../commons/SanityRichText";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const WaitlistJoin = ({data, isMobile, formHandler}) => {
  const {
    _rawWaitlistAppHeading,
    _rawWaitlistJoinSubheading,
    waitlistJoinCTA: cta,
  } = data;

  console.log(data);
  return (
    <div className={styler.get("waitlistJoinWrapper")}>
      <SanityRichText
        renderContainerOnSingleChild
        blocks={_rawWaitlistAppHeading}
        className={styler.get("heading")}
      />
      <div className={styler.get("subheading")}>
        <SanityRichText
          renderContainerOnSingleChild
          blocks={_rawWaitlistJoinSubheading}
          className={styler.get("subHeading")}
        />
      </div>
      {!isMobile && (
        <div className={styler.get("cta")}>
          <OutboundLink
            onClick={formHandler}
            href={cta?.url}
            id={cta?.idAttribute}
            target='_blank'
            referrerPolicy='no-referrer'
            rel='noopener noreferrer'
          >
            {cta?.text}
          </OutboundLink>
        </div>
      )}
    </div>
  );
};

export default WaitlistJoin;
